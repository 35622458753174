<template>
    <div style="background:#FCFCFC">
        <div class="container position-relative">
            <a
                @click="$router.go(-1)"
                class="position-absolute"
                style="left:2rem;opacity:0.5;color:black;text-decoration:none !important;cursor:pointer;"
            >
                <span class="triangle"></span> back
            </a>
            <div
                class="row d-flex flex-column justify-content-center flex-lg-row mx-xl-5 py-5"
            >
                <!-- shopping bag -->
                <div
                    class="
                        bg-white
                        rounded
                        shadow-sm
                        p-2
                        col-lg-8
                        col-xl-6
                        col-12
                        mb-3
                    "
                >
                    <div
                        class="d-flex align-items-center justify-content-center mb-md-3 justify-content-md-start"
                    >
                        <shopping-bag-icon class="text-success" />
                        <h3 class="m-0 fw-bold">Shopping Bag</h3>
                    </div>
                    <h2
                        class="title--tertiary text-center"
                        v-if="!carts || carts.length == 0"
                    >
                        There is no items in your cart.
                    </h2>
                    <div
                        class="
                        mt-3
                        d-flex
                        flex-column-reverse
                        flex-md-row
                        align-items-center
                        pb-3
                        border-bottom
                        justify-content-between
                    "
                        v-for="(cart, index) in carts"
                        :key="index"
                    >
                        <div>
                            <p class="p-0 m-0" style="font-size: 14px">
                                {{ cart.title }}
                            </p>
                            <div class="d-flex gap-2">
                                <p
                                    class="fw-bold m-0"
                                    v-if="
                                        cart.discounted_price && !selectedRedeem
                                    "
                                >
                                    {{
                                        addCommaForPrice(cart.discounted_price)
                                    }}
                                    MMK
                                </p>
                            </div>
                            <p
                                class="
                                    p-0
                                    m-0
                                "
                                style="font-size: 14px"
                                :class="
                                    cart.discounted_price && !selectedRedeem
                                        ? 'text-decoration-line-through'
                                        : 'fw-bold fs-6'
                                "
                            >
                                {{ addCommaForPrice(cart.final_price) }} MMK
                            </p>
                            <p class="p-0 pt-2 m-0" style="font-size: 14px">
                                {{ cart.gold_type }}
                            </p>
                            <!-- <p
                                    class="p-0 m-0 d-flex align-items-center"
                                    style="font-size: 14px;"
                                >
                                    <span>Amethyst x 1</span>
                                    <span>Diamond x 34</span>
                                </p> -->
                            <p class="p-0 m-0" style="font-size: 14px">
                                <!-- 4.92 Gram -->
                                {{ cart.gold_weight_gram }} Gram
                            </p>
                        </div>
                        <div class="position-relative">
                            <!-- <img
                                class="rounded shadow-sm"
                                src="@/assets/img/category-slide/512214PDAAAAP3_1 (Large).png"
                                alt=""
                            /> -->
                            <img
                                class="rounded shadow-sm"
                                :src="cart.photo"
                                alt=""
                                style="width:14.75rem;height:14.75rem"
                            />
                            <font-awesome-icon
                                icon="times-circle"
                                @click="removeFromCart(index)"
                                class="
                                cursor
                                position-absolute
                                mt-2
                            "
                                style="
                                width: 1.5rem !important;
                                height: 1.5rem !important;
                                opacity: 0.8;
                                right:0.5rem;
                            "
                            >
                                <span aria-hidden="true">×</span>
                            </font-awesome-icon>
                        </div>
                    </div>
                </div>

                <!-- payment -->
                <div class="col-12 col-md-6 col-lg-4" v-if="carts.length">
                    <div
                        class="bg-white rounded shadow-sm py-2 px-4"
                        style="height: max-content"
                    >
                        <!-- address -->
                        <div class="d-flex align-items-center">
                            <font-awesome-icon
                                icon="credit-card"
                                class="text-success"
                            />
                            <h3 class="m-0 ps-1 fw-bold">
                                Checkout
                            </h3>
                        </div>
                        <div v-if="all_shipping_addresses.length > 0">
                            <div
                                class="rounded p-2 my-2"
                                v-for="(address,
                                index) in all_shipping_addresses"
                                :key="index"
                                :class="
                                    selectedAddress.reference ==
                                    address.reference
                                        ? 'active-address'
                                        : 'address'
                                "
                            >
                                <label class="fw-bold">
                                    <input
                                        v-model="selectedAddress"
                                        name="address"
                                        type="radio"
                                        autocomplete="off"
                                        :value="address"
                                        checked
                                        style="accent-color: green;"
                                    />
                                    {{ address.reference }}
                                </label>
                                <p class="p-0 m-0" style="font-size: 14px">
                                    {{ address.reference_name }} -
                                    {{ address.reference_phone }}
                                </p>
                                <p class="p-0 m-0" style="font-size: 14px">
                                    {{ address.reference_address }}
                                </p>
                            </div>
                        </div>
                        <div class="text-center">
                            <!-- address modal trigger -->
                            <button class="add-btn" @click="addAddress(true)">
                                <font-awesome-icon icon="plus-circle" />
                                add new delivery address
                            </button>
                            <ShippingAddressForm
                                :use_in_order="user !== null"
                                v-model="shipping_address"
                                @prepared="prepareNewAddress"
                            />
                            <!-- modelhere -->
                        </div>

                        <!-- discount ticket -->
                        <div class="border my-3" v-show="user"></div>
                        <div v-show="user">
                            <label class="m-0 fw-bold">Discount Ticket</label>
                            <div
                                v-if="selectedRedeem"
                                class="
                                    position-relative
                                    discount-ticket
                                    d-flex
                                    align-items-start
                                    gap-1
                                "
                            >
                                <div class="coupon">
                                    <div
                                        class="d-flex flex-column align-items-center text-white"
                                        style="margin-top:0.5rem"
                                    >
                                        <font-awesome-icon
                                            v-if="
                                                selectedRedeem.point_type ==
                                                    'shwepyae'
                                            "
                                            :icon="['fas', 'crown']"
                                        />
                                        <font-awesome-icon
                                            v-if="
                                                selectedRedeem.point_type ==
                                                    'ngwepyae'
                                            "
                                            :icon="['far', 'gem']"
                                            style="transform: scaleY(-1);"
                                        />
                                        <div
                                            v-if="
                                                selectedRedeem.point_type ==
                                                    'referral'
                                            "
                                            class="octagon"
                                        ></div>
                                        <span>{{ selectedRedeem.point }}</span>
                                    </div>
                                </div>
                                <div
                                    class="
                                        d-flex
                                        flex-column
                                        align-items-start
                                        p-1
                                    "
                                >
                                    <span class="m-0">
                                        {{ discount }} MMK OFF
                                    </span>
                                    <span
                                        >{{ selectedRedeem.point }} points</span
                                    >
                                </div>
                                <font-awesome-icon
                                    icon="check-circle"
                                    style="
                                                color: #3cb207;
                                                margin-top: 5px;
                                            "
                                />
                                <font-awesome-icon
                                    @click="deleteRedeem"
                                    icon="trash"
                                    style="position: absolute; bottom:0.5rem; right:1.4rem; color: #E43225;cursor:pointer"
                                />
                            </div>

                            <div
                                v-if="selectedReferCode"
                                class="
                                    discount-ticket
                                    d-flex
                                    justify-content-between
                                    gap-1
                                "
                            >
                                <div
                                    class="
                                        d-flex
                                        flex-column
                                        align-items-start
                                        p-1
                                    "
                                >
                                    <span>{{ selectedReferCode.code }}</span>
                                    <span
                                        class="m-0"
                                        v-show="selectedReferCode.promo_price"
                                    >
                                        {{ selectedReferCode.promo_price }} MMK
                                        OFF
                                    </span>
                                    <span
                                        class="m-0"
                                        v-show="
                                            selectedReferCode.promo_percentage
                                        "
                                    >
                                        {{ selectedReferCode.promo_percentage }}
                                        % OFF
                                    </span>
                                </div>
                                <div
                                    class="
                                        d-flex
                                        flex-column
                                        align-items-end
                                        p-1
                                        gap-2
                                    "
                                >
                                    <font-awesome-icon
                                        icon="check-circle"
                                        style="
                                                color: #3cb207;
                                                margin-top: 5px;
                                            "
                                    />
                                    <font-awesome-icon
                                        @click="deleteReferCode"
                                        icon="trash"
                                        style="color: #E43225;cursor:pointer"
                                    />
                                </div>
                            </div>
                            <div
                                v-show="!selectedReferCode || !selectedRedeem"
                                :class="
                                    selectedReferCode || selectedRedeem
                                        ? ''
                                        : 'd-flex flex-column align-items-center gap-2 p-2 border'
                                "
                                style="border-radius:10px;opacity:1"
                            >
                                <button
                                    class="add-btn"
                                    style="width:fit-content"
                                    @click="redeemTicket"
                                    v-show="
                                        !selectedRedeem && !selectedReferCode
                                    "
                                >
                                    <font-awesome-icon icon="plus-circle" />
                                    Redeem ticket
                                </button>

                                <button
                                    class="add-btn"
                                    style="width:fit-content"
                                    @click="referCode"
                                    v-show="
                                        !selectedRedeem && !selectedReferCode
                                    "
                                >
                                    <font-awesome-icon icon="plus-circle" />
                                    Refer Code
                                </button>
                                <!-- <input
                                    type="text"
                                    class="add-btn"
                                    style="width:fit-content"
                                    placeholder="enter Referral code"
                                    v-model="refer_code"
                                    v-show="!selectedRedeem"
                                /> -->
                            </div>
                        </div>
                        <div class="border my-3"></div>

                        <!-- check payment -->

                        <table class="w-100">
                            <tbody>
                                <tr class="">
                                    <th class="py-1">Total</th>
                                    <td class="py-1 text-end">
                                        {{ addCommaForPrice(subTotal) }} MMK
                                    </td>
                                </tr>
                                <tr class="">
                                    <th class="py-1">Tax({{ totalTaxes }}%)</th>
                                    <!-- <td class="py-1">{{ discount }} MMK</td> -->
                                    <td class="py-1 text-end">
                                        {{ taxPrice }} MMK
                                    </td>
                                </tr>
                                <tr>
                                    <th class="py-1">Delivery Fees</th>
                                    <!-- <td class="py-1">{{ totalTaxes }} MMK</td> -->
                                    <td class="py-1 text-end">
                                        {{ addCommaForPrice(deliFee) }} MMK
                                    </td>
                                </tr>
                                <tr>
                                    <th class="py-1">
                                        Discount Ticket
                                    </th>
                                    <!-- <td class="py-1">{{ totalTaxes }} MMK</td> -->

                                    <td
                                        class="py-1 text-end"
                                        v-if="
                                            selectedRedeem || selectedReferCode
                                        "
                                    >
                                        {{ discount }} MMK
                                    </td>
                                    <td class="py-1 text-end" v-else>
                                        0 MMK
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr v-if="user">
                                    <th class="py-1 fw-bold">
                                        Grand Total:
                                    </th>
                                    <td
                                        class="
                                                    m-0
                                                    fw-bold
                                                    fs-5
                                                    text-end
                                                "
                                        style="color: #0c8410"
                                    >
                                        {{ addCommaForPrice(totalCost) }}
                                        MMK
                                    </td>
                                </tr>
                                <tr v-else>
                                    <th class="py-1 fw-bold">
                                        Grand Total:
                                    </th>
                                    <td
                                        class="
                                                    m-0
                                                    fw-bold
                                                    fs-5
                                                    text-end
                                                "
                                        style="color: #0c8410"
                                    >
                                        {{ addCommaForPrice(totalCost) }}
                                        MMK
                                    </td>
                                </tr>
                            </tfoot>
                        </table>

                        <!-- make payment -->
                        <button
                            class="shopping-bag-btn w-100"
                            @click="fillInfoBox"
                        >
                            Make Payment
                            <font-awesome-icon icon="chevron-right" />
                        </button>
                    </div>
                    <div class="text-center py-3">
                        <router-link
                            :to="{ name: 'ProductList' }"
                            class="text-dark"
                            >Continue Shopping</router-link
                        >
                    </div>
                </div>
            </div>
        </div>
        <transition name="fade">
            <div
                v-if="confirmBoxModal"
                class="modal confirmbox"
                id="confirmbox"
                tabindex="-1"
                aria-labelledby="confirmboxLabel"
                aria-hidden="true"
            >
                <div class="modal-dialog modal-lg modal-dialog-centered">
                    <div class="modal-content container">
                        <div class="modal-header border-0 mt-2">
                            <a
                                href="javascript:void(0)"
                                class="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                @click="confirmBoxModal = false"
                            ></a>
                        </div>
                        <div
                            class="modal-body"
                            style="height: 55vh;
                                       overflow: auto;"
                        >
                            <div class="d-flex">
                                <h2 class="flex-fill" id="exampleModalLabel">
                                    Please Confirm Your Details
                                </h2>
                                <div
                                    class="flex-fill pt-3 text-end"
                                    v-if="!isLoggedIn"
                                >
                                    I'm registered.
                                    <router-link
                                        :to="{ name: 'Login' }"
                                        class="login"
                                    >
                                        Login
                                    </router-link>
                                </div>
                            </div>
                            <form>
                                <div class="mb-3">
                                    <label for="name" class="col-form-label">
                                        Name
                                    </label>
                                    <input
                                        type="text"
                                        class="form-control border-0"
                                        id="name"
                                        v-model="order.name"
                                        :readonly="user"
                                    />
                                    <div
                                        class="
                                                    error
                                                    invalid-feedback
                                                    d-block
                                                "
                                        v-if="
                                            submitStatus == 'ERROR' &&
                                                !$v.order.name.required
                                        "
                                    >
                                        Name is required.
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <label
                                                for="Phone Number"
                                                class="col-form-label"
                                            >
                                                Phone Number
                                            </label>
                                            <input
                                                type="text"
                                                class="
                                                            form-control
                                                            border-0
                                                        "
                                                id="phone"
                                                v-model="order.phone"
                                                :readonly="user"
                                            />
                                            <div
                                                class="
                                                            error
                                                            invalid-feedback
                                                            d-block
                                                        "
                                                v-if="
                                                    submitStatus == 'ERROR' &&
                                                        !$v.order.phone.required
                                                "
                                            >
                                                Phone is required.
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <label
                                                for="Email"
                                                class="col-form-label"
                                            >
                                                Email
                                            </label>
                                            <input
                                                type="text"
                                                class="
                                                            form-control
                                                            border-0
                                                        "
                                                id="email"
                                                v-model="order.email"
                                                :readonly="user"
                                            />
                                            <div
                                                class="
                                                            error
                                                            invalid-feedback
                                                            d-block
                                                        "
                                                v-if="
                                                    submitStatus == 'ERROR' &&
                                                        !$v.order.email.required
                                                "
                                            >
                                                Email is required.
                                            </div>
                                            <div
                                                class="
                                                            error
                                                            invalid-feedback
                                                            d-block
                                                        "
                                                v-if="
                                                    submitStatus == 'ERROR' &&
                                                        !$v.order.email.email
                                                "
                                            >
                                                email must be email.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="mb-3"
                                    v-if="!isLoggedIn && order.term"
                                >
                                    <div class="row">
                                        <div class="col-md-6">
                                            <label
                                                for="Password"
                                                class="col-form-label"
                                            >
                                                Password
                                            </label>
                                            <input
                                                type="password"
                                                class="
                                                        form-control
                                                        border-0
                                                    "
                                                id="password"
                                                v-model="order.password"
                                            />
                                            <div
                                                class="error invalid-feedback d-block"
                                                v-if="
                                                    submitStatus == 'ERROR' &&
                                                        !$v.order.password
                                                            .required
                                                "
                                            >
                                                Password is required.
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <label
                                                for="Confirm Password"
                                                class="col-form-label"
                                            >
                                                Confirm Password
                                            </label>
                                            <input
                                                type="password"
                                                class="
                                                        form-control
                                                        border-0
                                                    "
                                                id="confirm-password"
                                                v-model="order.confirm_password"
                                            />
                                            <div
                                                class="error invalid-feedback d-block"
                                                v-if="
                                                    submitStatus == 'ERROR' &&
                                                        !$v.order
                                                            .confirm_password
                                                            .required
                                                "
                                            >
                                                Confirm Password is required.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <label
                                        for="address-text"
                                        class="col-form-label"
                                    >
                                        Full Address (for delivery)
                                    </label>
                                    <textarea
                                        class="form-control border-0"
                                        id="address-text"
                                        v-model="order.address"
                                        readonly
                                    ></textarea>
                                    <div
                                        class="
                                                            error
                                                            invalid-feedback
                                                            d-block
                                                        "
                                        v-if="
                                            submitStatus == 'ERROR' &&
                                                !$v.order.address.required
                                        "
                                    >
                                        Address is required.
                                    </div>
                                </div>
                                <div class="form-check" v-if="!isLoggedIn">
                                    <input
                                        class="form-check-input"
                                        type="checkbox"
                                        name="flexRadioDefault"
                                        id="accept-terms"
                                        v-model="order.term"
                                    />
                                    <label
                                        class="form-check-label"
                                        for="accept-terms"
                                    >
                                        {{ $t('profile.myCart.acceptTerms') }}
                                    </label>
                                </div>
                            </form>
                        </div>
                        <div
                            class="
                                        modal-footer
                                        border-0
                                        text-center
                                        d-block
                                        mt-3
                                        mb-2
                                    "
                        >
                            <button class="btn" @click="initToMakeOrder">
                                Place Order
                            </button>
                            <!-- <p>Mode of Payment: Cash on Delivery</p> -->
                        </div>
                    </div>
                </div>
            </div>
        </transition>
        <div id="addressModal">
            <!-- Modal-->
            <transition
                @enter="startTransitionModal"
                @after-enter="endTransitionModal"
                @before-leave="endTransitionModal"
                @after-leave="startTransitionModal"
            >
                <div
                    class="modal fade"
                    style="background: #0000001a"
                    v-if="redeemTicketBoxModal"
                    ref="modal"
                    @click.self="redeemTicketBoxModal = false"
                >
                    <div class="modal-dialog" role="document">
                        <div
                            class="modal-content py-3"
                            v-if="redeemTicketsArray.length > 0"
                        >
                            <div class="align-items-center">
                                <h5
                                    class="
                                        fw-bold
                                        text-center
                                        "
                                    id="addressModalLabel"
                                    style="
                                            font-size: 30px;
                                        "
                                >
                                    Redeem Tickets
                                </h5>
                                <font-awesome-icon
                                    icon="times-circle"
                                    @click="redeemTicketBoxModal = false"
                                    class="cursor"
                                    style="
                                        position: absolute;
                                        right: 15px;
                                        top: 22px;
                                        width: 1.5rem !important;
                                        height: 1.5rem !important;
                                        opacity: 0.8;
                                    "
                                >
                                    <span aria-hidden="true">×</span>
                                </font-awesome-icon>
                            </div>
                            <div
                                class="modal-body redeemTicket"
                                v-if="redeemTicketsArray.length > 0"
                                style=""
                            >
                                <div
                                    v-for="(redeem,
                                    index) in redeemTicketsArray"
                                    :key="index"
                                >
                                    <div
                                        @click="getRedeemTicket(redeem)"
                                        style="width: 15rem; height:70px"
                                        class="d-flex mb-2 cursor"
                                    >
                                        <div class="content" style="width:25%;">
                                            <div
                                                class="d-flex flex-column align-items-center text-white"
                                                style="margin-top:1rem"
                                            >
                                                <font-awesome-icon
                                                    v-if="
                                                        redeem.redeem
                                                            .point_type ==
                                                            'shwepyae'
                                                    "
                                                    :icon="['fas', 'crown']"
                                                />
                                                <font-awesome-icon
                                                    v-if="
                                                        redeem.redeem
                                                            .point_type ==
                                                            'ngwepyae'
                                                    "
                                                    :icon="['far', 'gem']"
                                                    style="transform: scaleY(-1);"
                                                />
                                                <div
                                                    v-if="
                                                        redeem.redeem
                                                            .point_type ==
                                                            'referral'
                                                    "
                                                    class="octagon"
                                                ></div>
                                                <span>{{
                                                    redeem.redeem.point
                                                }}</span>
                                            </div>
                                        </div>
                                        <div
                                            style="width: 70%;background:#FFFFFF"
                                            class="position-relative"
                                            :style="
                                                redeemCheckObject.id ===
                                                redeem.id
                                                    ? 'border: 1px solid #e4a825'
                                                    : 'border: 1px solid #0000000D'
                                            "
                                        >
                                            <div class="ms-1 pt-1">
                                                <div
                                                    style="position: absolute;right: 5px;"
                                                >
                                                    <div
                                                        class="circle"
                                                        :style="
                                                            redeemCheckObject.id ===
                                                            redeem.id
                                                                ? 'background: #e4a825'
                                                                : 'background: white;border: 1px solid #0000000d;'
                                                        "
                                                    >
                                                        <div
                                                            class="checkmark"
                                                        ></div>
                                                    </div>
                                                </div>
                                                <p
                                                    class="fw-bold"
                                                    style="font-size:16px; margin:0; padding:0"
                                                >
                                                    {{ redeem.redeem.price }}
                                                    MMK
                                                </p>
                                                <p
                                                    style="font-size:13px; margin:0; padding:0"
                                                >
                                                    Code -
                                                    {{ redeem.code }}
                                                </p>
                                                <!-- <p
                                                                class="redeem-card-btn"
                                                                style="font-size: 12px"
                                                            >
                                                                <span
                                                                    v-if="
                                                                        isUsing(
                                                                            redeem.code,
                                                                        )
                                                                    "
                                                                    class="useRedeem"
                                                                    >Selected</span
                                                                >
                                                                <span
                                                                    v-else
                                                                    class="useRedeem"
                                                                    @click="
                                                                        useRedeem(
                                                                            redeem,
                                                                        )
                                                                    "
                                                                    >Use
                                                                    now</span
                                                                >
                                                            </p> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button
                                class="use-now-btn"
                                @click="closeRedeemDialog"
                            >
                                Use Now
                            </button>
                        </div>
                        <div class="modal-content py-3" v-else>
                            <div class="align-items-center">
                                <h5
                                    class="
                                        fw-bold
                                        text-center
                                        "
                                    id="addressModalLabel"
                                    style="
                                            font-size: 20px;
                                        "
                                >
                                    သင့် မှာ Promotion Tickets မရှိသေးပါ။
                                </h5>
                                <font-awesome-icon
                                    icon="times-circle"
                                    @click="redeemTicketBoxModal = false"
                                    class="cursor"
                                    style="
                                        position: absolute;
                                        right: 15px;
                                        top: 22px;
                                        width: 1.5rem !important;
                                        height: 1.5rem !important;
                                        opacity: 0.8;
                                    "
                                >
                                    <span aria-hidden="true">×</span>
                                </font-awesome-icon>
                            </div>
                            <div class="modal-body">
                                Promotion Tickets ကို Point Benefits တွင်
                                ၀ယ်ယူနိုင်ပါသည်။
                            </div>
                            <button class="use-now-btn" @click="goToPointsPage">
                                Go to page
                            </button>
                        </div>
                    </div>
                </div>
            </transition>
            <div class="modal-backdrop fade d-none" ref="backdrop"></div>
        </div>
        <div id="addressModal">
            <!-- Modal-->
            <transition
                @enter="startTransitionModal"
                @after-enter="endTransitionModal"
                @before-leave="endTransitionModal"
                @after-leave="startTransitionModal"
            >
                <div
                    class="modal fade"
                    style="background: #0000001a"
                    v-if="referCodeBoxModal"
                    ref="modal"
                    @click.self="referCodeBoxModal = false"
                >
                    <div class="modal-dialog" role="document">
                        <div class="modal-content py-3">
                            <div class="align-items-center">
                                <h5
                                    class="
                                        fw-bold
                                        text-center
                                        "
                                    id="addressModalLabel"
                                    style="
                                            font-size: 30px;
                                        "
                                >
                                    Please enter your code
                                </h5>
                                <font-awesome-icon
                                    icon="times-circle"
                                    @click="referCodeBoxModal = false"
                                    class="cursor"
                                    style="
                                        position: absolute;
                                        right: 15px;
                                        top: 22px;
                                        width: 1.5rem !important;
                                        height: 1.5rem !important;
                                        opacity: 0.8;
                                    "
                                >
                                    <span aria-hidden="true">×</span>
                                </font-awesome-icon>
                            </div>
                            <div
                                class="modal-body d-flex flex-column align-items-center"
                            >
                                <input
                                    type="text"
                                    class="add-btn"
                                    style="width:fit-content"
                                    placeholder="Enter Refer Code"
                                    v-model="refer_code"
                                />
                                <span
                                    class="text-danger"
                                    v-if="code_error_msg"
                                    >{{ code_error_msg }}</span
                                >
                                <span class="text-danger" v-if="reponse_msg">{{
                                    code_error_msg
                                }}</span>
                            </div>
                            <button
                                class="use-now-btn"
                                @click="checkReferralCode"
                            >
                                Use Now
                            </button>
                        </div>
                    </div>
                </div>
            </transition>
            <div class="modal-backdrop fade d-none" ref="backdrop"></div>
        </div>
        <div id="addressModal" v-if="!isLoggedIn">
            <!-- Modal-->
            <transition
                @enter="startTransitionModal"
                @after-enter="endTransitionModal"
                @before-leave="endTransitionModal"
                @after-leave="startTransitionModal"
            >
                <div
                    class="modal fade"
                    style="background: #0000001a"
                    v-show="successModalBox"
                    ref="modal"
                    @click.self="successModalBox = false"
                >
                    <div class="modal-dialog" role="document">
                        <div class="modal-content py-3">
                            <div class="align-items-center">
                                <h5
                                    class="
                                        fw-bold
                                        text-center
                                        "
                                    id="addressModalLabel"
                                    style="
                                            font-size: 20px;
                                        "
                                >
                                    Order successful
                                </h5>
                                <font-awesome-icon
                                    icon="times-circle"
                                    @click="successModalBox = false"
                                    class="cursor"
                                    style="
                                        position: absolute;
                                        right: 15px;
                                        top: 22px;
                                        width: 1.5rem !important;
                                        height: 1.5rem !important;
                                        opacity: 0.8;
                                    "
                                >
                                    <span aria-hidden="true">×</span>
                                </font-awesome-icon>
                            </div>
                            <div class="modal-body text-center">
                                Please register to see your orders
                            </div>
                            <button class="to-register" @click="toRegister">
                                Go to Register
                            </button>
                        </div>
                    </div>
                </div>
            </transition>
            <div class="modal-backdrop fade d-none" ref="backdrop"></div>
        </div>
    </div>
</template>

<script>
//import ProfileAreaWrapper from '../components/reusable/ProfileAreaWrapper';
import ShippingAddressForm from '../../components/reusable/ShippingAddressForm.vue';
import { mapGetters } from 'vuex';
import CommaPrice from '@/mixins/CommaPrice';

// apollo query
import Taxes from '@/graphql/queries/order/Taxes.gql';
import DeliveryFee from '@/graphql/queries/deliveryFee/DeliveryFee.gql';
import { onLogin } from '../../graphql/vue-apollo';
import GetUser from '@/graphql/queries/auth/GetUser.gql';

// apollo mutation
import Checkout from '@/graphql/mutations/order/Checkout.gql';
import UserRegister from '@/graphql/mutations/order/UserRegisterCheckout.gql';

// vuelidate
import { validationMixin } from 'vuelidate';
import RewardHistory from '@/mixins/RedeemHistory';
// import RewardHistory from '../../mixins/RedeemHistory.js';
import { required, email, sameAs, requiredIf } from 'vuelidate/lib/validators';
import OrderDetail from '../../graphql/queries/order/OrderDetail.gql';
import AddToCart from '../../mixins/AddToCart';
import CheckRedeem from '@/graphql/mutations/order/CheckRedeem.gql';
import CheckReferral from '@/graphql/mutations/order/CheckReferral.gql';

// document.getElementById('addressForm').type = 'submit';
// document.getElementById('addressButton').submit();

export default {
    mixins: [validationMixin, CommaPrice, AddToCart, RewardHistory],
    components: {
        ShippingAddressForm,
    },
    data() {
        return {
            authError: '',
            fillBoxModal: false,
            confirmBoxModal: false,
            orderedMsgModal: false,
            submitStatus: null,
            product_variants: [],
            // subTotal: 0,
            grandTotal: 0,
            getDeliFee: 0,
            totalTaxes: 0,
            // discount: 0,
            // is_used_refer_code: false,
            refer_code: '',
            // is_used_redeem_code: false,
            is_new_user: false,
            redeemCheckObject: {},
            redeemId: '',
            checkRedeemTickect: '',
            has_categories: [],
            ordered: {
                type: '',
                message: '',
            },
            order: {
                name: '',
                phone: '',
                email: '',
                password: '',
                confirm_password: '',
                shippingaddress: '',
                term: false,
            },
            isLoadForOrder: false,
            shipping_address: {
                reference: '',
                reference_name: '',
                reference_phone: '',
                reference_address: '',
                is_save: false,
            },
            redeemInput: {},
            new_shipping_address_array: [],
            redeemTicketBoxModal: false,
            referCodeBoxModal: false,
            selectedAddress: '',
            isNewAddress: false,
            all_shipping_addresses: [],
            defaultAddressIndex: '',
            defaultAddress: {
                reference: '',
                reference_address: '',
                reference_name: '',
                reference_phone: '',
                is_save: false,
            },
            successModalBox: false,
            referral: null,
            code_error_msg: '',
            reponse_msg: '',
        };
    },

    validations() {
        return {
            order: {
                name: {
                    required,
                },
                phone: {
                    required,
                },
                email: {
                    required: requiredIf(function() {
                        if (!this.isLoggedIn && this.order.term) {
                            return true;
                        } else if (this.isLoggedIn) {
                            return true;
                        } else {
                            return false;
                        }
                    }),
                    email,
                },
                password: {
                    required: requiredIf(function() {
                        return !this.isLoggedIn && this.order.term;
                    }),
                },
                confirm_password: {
                    required: requiredIf(function() {
                        return !this.isLoggedIn && this.order.term;
                    }),
                    sameAsPassword: sameAs('password'),
                },
                address: {
                    required,
                },
            },
        };
    },
    mounted() {
        if (this.carts.length > 0) {
            this.updatedCheckedVariants();
        }
        this.getTaxes();
        this.getDeliveryFee();
        this.getRedeemHistory();
        // this.addTotal();
        // if (this.user && this.user.shipping_address) {
        //     this.shipping_address = {
        //         reference: 'Default Address',
        //         reference_name: this.user.name,
        //         reference_phone: this.user.phone,
        //         reference_address: this.user.shipping_address,
        //         is_save: false,
        //     };
        // }
        // if (this.user) {
        //     this.shipping_address = this.all_shipping_addresses[0];
        // }
        // if (this.user) {
        //     this.shipping_address = {
        //         reference: 'Default Address',
        //         reference_name: this.user.name,
        //         reference_phone: this.user.phone,
        //         reference_address: this.user.shipping_address,
        //         is_save: false,
        //     };
        // }
        if (this.selectedRedeem) {
            // this.is_used_redeem_code = true;
            //this.calculateWithRedeem();
            this.redeemCheckObject.id = this.selectedRedeem.redeem_user_id;
        }
    },
    computed: {
        ...mapGetters({
            carts: 'cart/getCarts',
            taxes: 'order/getTaxes',
            isLoggedIn: 'auth/isLoggedIn',
            user: 'auth/getUser',
            selectedRedeem: 'home/getSelectedRedeem',
            selectedReferCode: 'home/getSelectedReferCode',
            redeemLoyalHistories: 'point/getRedeemLoyalHistories',
            redeemReferralHistories: 'point/getRedeemActivityHistories',
            redeemActivityHistories: 'point/getRedeemReferralHistories',
        }),
        redeemTicketsArray() {
            return this.redeemLoyalHistories.concat(
                this.redeemReferralHistories,
                this.redeemActivityHistories,
            );
        },
        netTotal() {
            return this.subTotal - this.discount;
        },
        taxPrice() {
            // return this.netTotal * (this.totalTaxes / 100);
            let taxRate = this.totalTaxes / 100;
            return Math.ceil((this.netTotal * taxRate) / 50) * 50;
        },
        deliFee() {
            if (this.user) {
                return this.user?.deliveryFee;
            } else {
                return this.getDeliFee;
            }
        },
        totalCost() {
            if (this.user) {
                return this.netTotal + this.taxPrice + this.deliFee;
            } else {
                return this.netTotal + this.taxPrice;
            }
        },
        discount() {
            return this.calculateWithRedeem();
        },
        subTotal() {
            const total = this.carts.reduce((acc, cart) => {
                var price =
                    cart.discounted_type !== 0 && !this.selectedRedeem
                        ? cart.discounted_price
                        : cart.final_price;
                return acc + price * cart.quantity;
            }, 0);
            return total;
        },
        // isUsedReferCode() {
        //     return this.refer_code
        //         ? return this.is_used_refer_code = true
        //         : return this.is_used_refer_code = false;
        // },
    },

    watch: {
        user() {
            if (this.user) {
                // this.setDefaultAddress();

                this.shipping_address = {
                    reference: 'Default Address',

                    reference_name: this.user.name,

                    reference_phone: this.user.phone,

                    reference_address: this.user.shipping_address ?? '',

                    is_save: false,
                };

                this.selectedAddress = this.shipping_address;

                this.all_shipping_addresses = [
                    ...[this.shipping_address],

                    ...this.user.shipping_addresses,
                ];
            }
        },
    },

    methods: {
        checkReferralCode() {
            this.$apollo
                .mutate({
                    mutation: CheckReferral,

                    variables: {
                        user_id: this.user.id,
                        code: this.refer_code,
                    },
                })
                .then(response => {
                    const referral = response.data.checkReferralCode;
                    this.code_error_msg = '';
                    this.response_msg = referral.message;
                    if (referral.status == 'success') {
                        let referCode = {
                            ...referral,
                            code: this.refer_code,
                        };
                        this.$store.commit(
                            'home/ADD_SELECTED_REFER_CODE',
                            referCode,
                        );
                        this.referCodeBoxModal = false;
                    } else {
                        this.code_error_msg = referral.message;
                    }
                    // if (referral.status == 'success') {
                    //     this.is_used_refer_code = true;
                    //     this.code_error_msg = '';
                    //     this.response_msg = referral.message;
                    //     if (referral.promo_type == 'percentage') {
                    //         this.carts.map(cart => {
                    //             if (
                    //                 cart.category_id == referral.category_id ||
                    //                 referral.category_id == 0
                    //             ) {
                    //                 let price =
                    //                     (cart.final_price / 100) *
                    //                     referral.promo_percentage;
                    //                 console.log(price);
                    //                 this.subTotal =
                    //                     this.subTotal -
                    //                     Math.round(price) * cart.quantity;
                    //                 console.log(this.subTotal);
                    //             }
                    //         });
                    //     } else {
                    //         this.carts.map(cart => {
                    //             if (
                    //                 cart.category_id == referral.category_id ||
                    //                 referral.category_id == 0
                    //             ) {
                    //                 this.subTotal =
                    //                     this.subTotal - this.discount;
                    //             }
                    //         });
                    //     }
                    // } else {
                    //     this.code_error_msg = referral.message;
                    // }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        calculateWithRedeem() {
            let discount = 0;
            if (this.carts && this.carts.length > 0) {
                discount = this.carts.reduce((accumulator, currentValue) => {
                    if (
                        (this.selectedRedeem &&
                            currentValue.category_id ==
                                this.selectedRedeem?.category_id) ||
                        this.selectedRedeem?.category_id == 0
                    ) {
                        let dis_price = 0;

                        if (this.selectedRedeem?.promo_type == 'percentage') {
                            dis_price =
                                (currentValue.final_price / 100) *
                                this.selectedRedeem?.promo_percentage;
                        } else {
                            dis_price = this.selectedRedeem?.promo_price;
                        }

                        return (
                            accumulator +
                            Math.round(dis_price) * currentValue.quantity
                        );
                    }

                    if (
                        (this.selectedReferCode &&
                            currentValue.category_id ==
                                this.selectedReferCode?.category_id) ||
                        this.selectedReferCode?.category_id == 0
                    ) {
                        let dis_price = 0;

                        if (
                            this.selectedReferCode?.promo_type == 'percentage'
                        ) {
                            dis_price =
                                (currentValue.final_price / 100) *
                                this.selectedReferCode?.promo_percentage;
                        } else {
                            dis_price = this.selectedReferCode?.promo_price;
                        }

                        return (
                            accumulator +
                            Math.round(dis_price) * currentValue.quantity
                        );
                    }
                    return 0;
                }, 0);
            }

            return discount;
        },
        getRedeemTicket(redeem) {
            this.redeemCheckObject = redeem;
            // this.redeemInput = redeem;
            //this.useRedeem(redeem);
        },
        closeRedeemDialog() {
            // this.is_used_redeem_code = true;
            this.useRedeem(this.redeemCheckObject);
            this.redeemTicketBoxModal = false;
        },
        closeReferCodeDialog() {
            // this.is_used_refer_code = true;
            this.referCodeBoxModal = false;
        },
        fillInfoBox() {
            // if (!this.isLoggedIn || this.user.shipping_addresses.length === 0) {
            //     this.$router.push('/login');
            // } else {
            // if (!this.user) {
            //     this.$router.push({
            //         name: 'Login',
            //         query: {
            //             slug: 'shoppingBag',
            //         },
            //     });
            //     return;
            // }
            // if (this.all_shipping_addresses.length === 0) {
            //     this.addAddress();
            //     this.is_new_user = true;
            //     return;
            // }
            if (this.isLoggedIn && this.user) {
                // this.order.name = this.user.name;
                // this.order.phone = this.user.phone;
                if (!this.selectedAddress.reference_address) {
                    this.defaultAddressIndex = this.all_shipping_addresses.findIndex(
                        sh => sh == this.selectedAddress,
                    );
                    this.addAddress();
                    this.is_new_user = true;
                    return;
                }
                this.order.email = this.user.email;
                // this.order.term = true;
                this.confirmBoxModal = true;
            } else {
                if (!this.selectedAddress.reference_address) {
                    this.defaultAddressIndex = this.all_shipping_addresses.findIndex(
                        sh => sh == this.selectedAddress,
                    );
                    this.addAddress(true);
                    this.is_new_user = true;
                    return;
                } else {
                    this.confirmBoxModal = true;
                }
                // this.fillBoxModal = true;
                // this.$router.push('/login');
            }

            this.order.address =
                this.shipping_address.reference +
                ' , ' +
                this.shipping_address.reference_name +
                ' ( ' +
                this.shipping_address.reference_phone +
                ' ), ' +
                this.shipping_address.reference_address;
            if ('is_save' in this.shipping_address === false) {
                this.shipping_address.is_save = false;
            }
            this.order.name = this.shipping_address.reference_name;
            this.order.phone = this.shipping_address.reference_phone;
            delete this.shipping_address.__typename;
            // delete this.shipping_address.id;
            if ('id' in this.shipping_address) {
                delete this.shipping_address.id;
            }
        },
        placeOrder() {
            alert(this.$v.$invalid);
            if (this.$v.$invalid) {
                this.submitStatus = 'ERROR';
            } else {
                this.updatedCheckedVariants();
                if (!this.isLoggedIn) {
                    if (this.order.term) {
                        this.$apollo
                            .mutate({
                                mutation: UserRegister,

                                variables: {
                                    name: this.order.name,
                                    email: this.order.email,
                                    phone: this.order.phone,
                                    password: this.order.password,
                                    password_confirmation: this.order
                                        .confirm_password,
                                    shipping_address: this.order.address,
                                },
                            })
                            .then(response => {
                                this.$store.commit(
                                    'auth/ADD_TOKEN',
                                    response.data.userRegister.tokens
                                        .access_token,
                                );
                                this.$store.commit(
                                    'auth/ADD_REFRESH_TOKEN',
                                    response.data.userRegister.tokens
                                        .refresh_token,
                                );

                                onLogin(
                                    this.$apollo.provider.defaultClient,
                                    response.data.userRegister.tokens
                                        .access_token,
                                );

                                this.$apollo
                                    .getClient()
                                    .resetStore()
                                    .then(() => {
                                        this.$apollo
                                            .query({
                                                query: GetUser,
                                                fetchPolicy: 'no-cache',
                                            })
                                            .then(response => {
                                                this.$store.commit(
                                                    'auth/ADD_USER',
                                                    response.data,
                                                );
                                            })
                                            .catch(error => {
                                                console.log(error);
                                            });
                                    });

                                this.fillBoxModal = false;
                                this.confirmBoxModal = true;
                            })
                            .catch(errors => {
                                console.log(errors);
                                this.submitStatus = 'ERROR';
                                // this.authError = errors.graphQLErrors[0]
                                //     .extensions.validation['input.email'][0]
                                //     ? 'The email is already been taken.'
                                //     : errors.graphQLErrors[0].extensions.reason;
                            });
                    } else {
                        this.fillBoxModal = false;
                        this.confirmBoxModal = true;
                    }
                } else {
                    this.fillBoxModal = false;
                    this.confirmBoxModal = true;
                }
            }
        },
        formatProductVariant() {
            if (this.carts && this.carts.length > 0) {
                this.carts.map(item => {
                    this.product_variants.push({
                        product_id: item.id,
                        product_name: item.title,
                        product_variant_id: item.product_variant_id,
                        product_variant_name: item.product_variant_name,
                        price: item.final_price,
                        quantity: item.quantity,
                    });
                });
            }
        },
        initToMakeOrder() {
            if (this.$v.$invalid) {
                this.submitStatus = 'ERROR';
                // console.log(this.$v.$invalid);
                return;
            }
            if (!this.isLoggedIn) {
                if (this.order.term) {
                    this.$apollo
                        .mutate({
                            mutation: UserRegister,

                            variables: {
                                name: this.order.name,
                                email: this.order.email,
                                phone: this.order.phone,
                                password: this.order.password,
                                password_confirmation: this.order
                                    .confirm_password,
                                shipping_address: this.order.address,
                            },
                        })
                        .then(response => {
                            // console.log(response.data);

                            this.$store.commit(
                                'auth/ADD_TOKEN',
                                response.data.userRegister.tokens.access_token,
                            );
                            this.$store.commit(
                                'auth/ADD_REFRESH_TOKEN',
                                response.data.userRegister.tokens.refresh_token,
                            );

                            onLogin(
                                this.$apollo.provider.defaultClient,
                                response.data.userRegister.tokens.access_token,
                            );

                            this.$apollo
                                .getClient()
                                .resetStore()
                                .then(() => {
                                    this.$apollo
                                        .query({
                                            query: GetUser,
                                            fetchPolicy: 'no-cache',
                                        })
                                        .then(response => {
                                            this.$store.commit(
                                                'auth/ADD_USER',
                                                response.data,
                                            );
                                        })
                                        .catch(error => {
                                            console.log(error);
                                        });
                                });

                            this.fillBoxModal = false;
                            this.confirmBoxModal = true;
                            this.makeOrder();
                        })
                        .catch(errors => {
                            console.log(errors);
                            this.submitStatus = 'ERROR';
                            this.authError = errors.graphQLErrors[0].extensions
                                .validation['input.email'][0]
                                ? 'The email is already been taken.'
                                : errors.graphQLErrors[0].extensions.reason;
                        });
                } else {
                    this.makeOrder();
                }
            } else {
                this.makeOrder();
            }
        },
        makeOrder() {
            this.isLoadForOrder = true;
            this.formatProductVariant();
            this.$apollo
                .mutate({
                    mutation: Checkout,

                    variables: {
                        data: {
                            is_login_user: this.isLoggedIn,
                            currency: 'MMK',
                            name: this.order.name,
                            email: this.order.email,
                            phone: this.order.phone,
                            delivery_fee: this.deliFee,
                            shipping_address: this.shipping_address,
                            product_variants: this.product_variants,
                            taxes: this.taxes,
                            discount: this.discount,
                            is_used_refer_code: this.selectedReferCode
                                ? true
                                : false,
                            refer_code: this.selectedReferCode?.code,
                            redeem_code: this.selectedRedeem?.code,
                            is_used_redeem_code: this.selectedRedeem
                                ? true
                                : false,
                            redeem_user_id: this.selectedRedeem?.redeem_user_id,
                        },
                    },
                })
                .then(response => {
                    switch (response.data.checkout.code) {
                        case 1:
                        case 2:
                            this.ordered.type = 'alert-success';
                            this.ordered.message = 'Successfully Ordered!';
                            // this.subTotal = 0;
                            //this.discount = 0;
                            // this.calculateTotalCost();

                            this.$store.commit('cart/RESET_CART');
                            // val.reset();

                            this.$store.commit('order/MAKE_ORDER', true);

                            this.isLoadForOrder = false;
                            if (this.isLoggedIn) {
                                this.$router.push({
                                    name: 'OrderDetail',
                                    query: {
                                        slug: response.data.checkout.order.id,
                                    },
                                    params: {
                                        slug: response.data.checkout.order.id,
                                    },
                                });
                            }
                            this.deleteRedeem();
                            this.deleteReferCode();
                            break;
                        case 3:
                        case 4:
                            this.ordered.type = 'alert-warning';
                            this.ordered.message =
                                'Please refresh your browser to get updated products!';
                            this.orderedMsgModal = true;
                            this.isLoadForOrder = false;

                            break;
                        case 5:
                            this.ordered.type = 'alert-error';
                            this.ordered.message =
                                response.response.data.checkout.message;
                            this.orderedMsgModal = true;
                            this.isLoadForOrder = false;

                            break;
                        case 6:
                            this.ordered.type = 'alert-error';
                            this.ordered.message =
                                response.response.data.checkout.message;
                            this.orderedMsgModal = true;
                            this.isLoadForOrder = false;

                            break;
                    }
                    this.confirmBoxModal = false;
                    this.successModalBox = true;
                })
                .catch(error => {
                    console.log(error);
                    this.confirmBoxModal = false;
                });
        },
        useRedeem(redeemInput) {
            this.$apollo
                .mutate({
                    mutation: CheckRedeem,

                    variables: {
                        user_id: this.user.id,
                        code: redeemInput.code,
                    },
                })
                .then(response => {
                    const redeem = response.data.checkRedeemCode;
                    if (redeem.status == 'success') {
                        let selectRedeem = {
                            ...redeem,
                            code: redeemInput.code,
                            point: redeemInput.redeem.point,
                            point_type: redeemInput.redeem.point_type,
                        };
                        this.$store.commit(
                            'home/ADD_SELECTED_REDEEM',
                            selectRedeem,
                        );
                    } else {
                        alert(redeem.message);
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        getDeliveryFee() {
            this.$apollo
                .query({
                    query: DeliveryFee,
                })
                .then(response => {
                    // console.log(response.data);
                    this.getDeliFee = response.data.deliveryFee.amount;
                })
                .catch(error => {
                    console.log(error.response);
                });
        },
        getTaxes() {
            this.$apollo
                .query({
                    query: Taxes,
                })
                .then(response => {
                    if (response.data.taxes.length > 0) {
                        let taxesArr = [
                            {
                                id: null,
                                name: null,
                                tax: null,
                            },
                        ];
                        response.data.taxes.map(t => {
                            taxesArr = [];
                            taxesArr.push({
                                id: t.id,
                                name: t.name,
                                tax: t.tax,
                            });
                        });
                        this.$store.commit('order/ADD_TAXES', taxesArr);
                        const taxes = response.data.taxes;
                        // console.log(taxes);
                        taxes.map(tax => {
                            this.totalTaxes = this.totalTaxes + tax.tax;
                        });
                    }
                })
                .catch(error => {
                    console.log(error.response);
                });
        },
        redeemTicket() {
            if (this.isLoggedIn) {
                this.redeemTicketBoxModal = true;
            } else {
                this.$router.push({
                    name: 'Login',
                    query: {
                        slug: 'shoppingBag',
                    },
                });
            }
        },
        referCode() {
            if (this.isLoggedIn) {
                this.referCodeBoxModal = true;
            } else {
                this.$router.push({
                    name: 'Login',
                    query: {
                        slug: 'shoppingBag',
                    },
                });
            }
        },
        isUsing(code) {
            return this.selectedRedeem && this.selectedRedeem.code === code;
        },
        removeFromCart(index) {
            this.$store.commit('cart/REMOVE_CART', index);
            // this.subTotal = 0;
            // this.addTotal();
        },
        addAddress(isNewAddress = false) {
            // if (!this.isLoggedIn) {
            //     this.$router.push({
            //         name: 'Login',
            //         query: {
            //             slug: 'shoppingBag',
            //         },
            //     });

            //     return;
            // }
            if (!this.user) {
                this.is_new_user = true;
            }
            if (isNewAddress) {
                this.shipping_address = this.defaultAddress;
            }
            if (
                !isNewAddress &&
                !this.selectedAddress.reference_address &&
                this.user
            ) {
                this.shipping_address = this.selectedAddress;
            }
            this.isNewAddress = isNewAddress;
            this.$emit('openAddressModel');
        },
        prepareNewAddress() {
            if (this.isNewAddress) {
                this.all_shipping_addresses.push(this.shipping_address);
            }
            if (this.defaultAddressIndex >= 0) {
                this.all_shipping_addresses[
                    this.defaultAddressIndex
                ] = this.shipping_address;
            }
            this.selectedAddress = this.shipping_address;
            // console.log(this.is_new_user);
            if (this.is_new_user) {
                this.fillInfoBox();
            }
        },
        getOrder(id) {
            this.$apollo
                .query({
                    query: OrderDetail,
                    variables: {
                        id: id,
                    },
                })
                .then(response => {
                    this.$store.commit('order/ADD_ORDER', response.data.order);
                })
                .catch(error => {
                    console.log(error.response);
                });
        },
        setDefaultAddress() {
            if (this.user) {
                this.shipping_address = this.all_shipping_addresses[0];
            }
        },
        deleteRedeem() {
            this.$store.commit('home/ADD_SELECTED_REDEEM', null);
            //this.discount = 0;
        },
        deleteReferCode() {
            this.$store.commit('home/ADD_SELECTED_REFER_CODE', null);
        },
        startTransitionModal() {
            this.$refs.backdrop.classList.toggle('d-block');
            this.$refs.modal.classList.toggle('d-block');
        },
        endTransitionModal() {
            this.$refs.backdrop.classList.toggle('show');
            this.$refs.modal.classList.toggle('show');
        },
        goToPointsPage() {
            this.$router.push({ name: 'Points' });
        },
        toRegister() {
            this.successModalBox = false;
            this.$router.push({
                name: 'Register',
            });
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/sass/components/profileLayout.scss';
@import '@/sass/components/cartList.scss';
$size: 13px;
.close-alert-order {
    color: #0f5132;
    text-decoration: none;
    float: right;
    font-size: 20px;
    line-height: 23px;
}

.redeem-text,
.refer-text {
    cursor: pointer;
}

.redeem,
.refer {
    button {
        font-size: 15px;
    }
}

.address {
    border: 1px solid #00000029;
}
.active-address {
    border: 1px solid #3cb207;
}
.add-btn {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #0000000d;
    border-radius: 10px;
    opacity: 1;
}
.discount-ticket {
    width: 220px;
    height: 54px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #0000000d;
    border-radius: 10px;
    opacity: 1;
    margin: 0.5rem 0;
    .coupon {
        width: 48px;
        height: 54px;
        background: #466be5 0% 0% no-repeat padding-box;
        border-radius: 10px 0px 0px 10px;
        opacity: 1;
    }
}
.input-icon {
    background: white;
    border-right-color: 1px solid white;
    padding-right: 0;
}

.triangle::after {
    position: absolute;
    top: 50%;
    right: 115%;
    margin-top: -6px;
    content: ' ';
    width: 0;
    height: 0;
    border-left: 0.4rem solid transparent;
    border-right: 0.4rem solid black;
    border-bottom: 0.4rem solid transparent;
    border-top: 0.4rem solid transparent;
}
.octagon {
    clip-path: polygon(
        48% 0,
        49% 0,
        100% 30%,
        100% 70%,
        48% 100%,
        48% 100%,
        0% 70%,
        0% 30%
    );
    width: 1rem;
    background: white;
    height: 1.5rem;
}
.discount-ticket {
    width: 220px;
    height: 54px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #0000000d;
    border-radius: 10px;
    opacity: 1;
    margin: 0.5rem 0;
    .coupon {
        width: 48px;
        height: 54px;
        background: #466be5 0% 0% no-repeat padding-box;
        border-radius: 10px 0px 0px 10px;
        opacity: 1;
    }
}
.useRedeem {
    background: #e4a825 0% 0% no-repeat padding-box;
    border-radius: 18px;
    opacity: 1;
    color: #fff;
    padding: 0 0.2rem;
    cursor: pointer;
    border-style: none;
}
.content {
    width: 65px;
    height: 100%;
    -webkit-mask: radial-gradient(circle at top, transparent 10px, red 0),
        radial-gradient(circle at bottom, transparent 10px, red 0);
    -webkit-mask-composite: source-in;
    background: #e4a825 0% 0% no-repeat padding-box;
}
.use-now-btn {
    margin: 0 auto;
    background: #3cb207 0% 0% no-repeat padding-box;
    border-radius: 10px;
    width: 6rem;
    opacity: 1;
    border: none;
    padding: 0.5rem 0;
    color: #fff;
}
.circle {
    position: relative;
    border-radius: 50%;
    width: $size;
    height: $size;
}

.checkmark {
    position: absolute;
    transform: rotate(45deg) translate(-50%, -50%);
    left: 35%;
    top: 45%;
    height: $size/3;
    width: $size/6;
    border-bottom: 1px solid #fff;
    border-right: 1px solid #fff;
}
.redeemTicket {
    display: flex;
    /* align-items: center; */
    flex-wrap: wrap;
    justify-content: center;
    height: 50vh;
    overflow: auto;
}
@media (min-width: 576px) {
    .redeemTicket {
        display: grid;
        grid-template-columns: auto auto;
        justify-content: start;
        margin-bottom: 1rem;
    }
}
.to-register {
    margin: 0 auto;
    background: #3cb207 0% 0% no-repeat padding-box;
    border-radius: 10px;
    width: fit-content;
    opacity: 1;
    border: none;
    padding: 0.5rem 0.5rem;
    color: #fff;
}
</style>
