<template>
    <div id="addressModal">
        <!-- Modal-->
        <transition
            @enter="startTransitionModal"
            @after-enter="endTransitionModal"
            @before-leave="endTransitionModal"
            @after-leave="startTransitionModal"
        >
            <div
                class="modal fade"
                style="background: #0000001a"
                v-show="showAddressModal"
                ref="modal"
                @click.self="closeForm"
            >
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div
                            class="
                    modal-header
                    align-items-start
                "
                        >
                            <h5
                                class="
                        modal-title
                        fw-bold
                        text-start
                    "
                                id="addressModalLabel"
                                style="
                        font-size: 30px;
                    "
                            >
                                Add new delivery address
                            </h5>
                            <font-awesome-icon
                                icon="times-circle"
                                @click="closeForm"
                                class="
                        cursor
                        mt-2
                    "
                                style="
                        width: 1.5rem !important;
                        height: 1.5rem !important;
                        opacity: 0.8;
                    "
                            >
                                <span aria-hidden="true">×</span>
                            </font-awesome-icon>
                        </div>
                        <form @submit.prevent="submitAddress">
                            <div class="modal-body">
                                <div class="mb-2 mx-2 ">
                                    <div class="input-group">
                                        <span
                                            class="input-group-text input-icon"
                                        >
                                            <tag-icon />
                                        </span>
                                        <input
                                            type="text"
                                            class="form-control"
                                            placeholder="Address Reference(John's Home)"
                                            style="border-left-color: white;"
                                            v-model="reference"
                                            required
                                        />
                                    </div>
                                </div>
                                <div
                                    class="
                        mb-2
                        mx-2
                    "
                                >
                                    <div
                                        class="
                            input-group
                        "
                                    >
                                        <span
                                            class="
                                input-group-text
                                input-icon
                            "
                                        >
                                            <user-icon />
                                        </span>
                                        <input
                                            type="text"
                                            class="
                                form-control
                            "
                                            placeholder="Receiver Name"
                                            style="
                                border-left-color: white;
                            "
                                            v-model="reference_name"
                                            required
                                        />
                                    </div>
                                </div>

                                <div
                                    class="
                        mb-2
                        mx-2
                    "
                                >
                                    <div
                                        class="
                            input-group
                        "
                                    >
                                        <span
                                            class="
                                input-group-text
                                input-icon
                            "
                                        >
                                            <phone-icon />
                                        </span>
                                        <input
                                            type="text"
                                            class="
                                form-control
                            "
                                            placeholder="Receiver Phone"
                                            style="
                                border-left-color: white;
                            "
                                            v-model="reference_phone"
                                            required
                                            :pattern="phoneRegex"
                                            title="Phone number must be between 7 to 11 digits"
                                        />
                                    </div>
                                </div>
                                <div
                                    class="
                        mb-2
                        mx-2
                    "
                                >
                                    <div
                                        class="
                            input-group
                            d-fle
                        "
                                    >
                                        <span
                                            class="
                                input-group-text
                                input-icon
                                align-items-start
                            "
                                        >
                                            <location-icon />
                                        </span>
                                        <textarea
                                            class="
                                form-control
                            "
                                            type="text"
                                            placeholder="Receiver Address"
                                            style="
                                border-left-color: white;
                            "
                                            rows="3"
                                            required
                                            v-model="reference_address"
                                        ></textarea>
                                    </div>
                                </div>
                            </div>

                            <div
                                class="
                    modal-footer
                    d-flex
                    flex-column
                "
                                v-if="use_in_order"
                            >
                                <button
                                    type="submit"
                                    id="addressButton"
                                    class="
                        shopping-bag-btn
                        w-100
                    "
                                    name="action"
                                    value="save"
                                    @click="setAction('save')"
                                >
                                    Save and Use
                                </button>
                                <button
                                    type="submit"
                                    class="
                                    btn btn-text
                        text-dark
                        text-decoration-underline
                    "
                                    style="cursor: pointer"
                                    name="action"
                                    value="submit"
                                    @click="setAction('submit')"
                                >
                                    Use only for this order
                                </button>
                            </div>
                            <div v-else class="modal-footer">
                                <button
                                    type="submit"
                                    id="addressButton"
                                    class="
                        shopping-bag-btn
                        w-100
                    "
                                >
                                    Save Delivery Address
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </transition>
        <div class="modal-backdrop fade d-none" ref="backdrop"></div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
    props: {
        shipping_address: {
            type: Object,
        },
        use_in_order: {
            type: Boolean,
            default: false,
        },
        value: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            showAddressModal: false,
            action: '',
            phoneRegex: '^\\d{7,11}$',
        };
    },
    mounted() {
        this.$parent.$on('openAddressModel', () => {
            this.showAddressModal = true;
        });
    },
    computed: {
        ...mapGetters({
            user: 'auth/getUser',
        }),
        reference: {
            get() {
                return this.value.reference;
            },
            set(value) {
                this.$emit('input', { ...this.value, reference: value });
            },
        },
        reference_name: {
            get() {
                return this.value.reference_name;
            },
            set(value) {
                this.$emit('input', { ...this.value, reference_name: value });
            },
        },
        reference_phone: {
            get() {
                return this.value.reference_phone;
            },
            set(value) {
                this.$emit('input', { ...this.value, reference_phone: value });
            },
        },
        reference_address: {
            get() {
                return this.value.reference_address;
            },
            set(value) {
                this.$emit('input', {
                    ...this.value,
                    reference_address: value,
                });
            },
        },
    },
    methods: {
        startTransitionModal() {
            this.$refs.backdrop.classList.toggle('d-block');
            this.$refs.modal.classList.toggle('d-block');
        },
        endTransitionModal() {
            this.$refs.backdrop.classList.toggle('show');
            this.$refs.modal.classList.toggle('show');
        },
        setAction(action) {
            this.action = action;
        },
        submitAddress() {
            if (this.action === 'save') {
                // handle save action
                // this.form.is_save = true;
                this.$emit('input', { ...this.value, is_save: true });
            } else if (this.action === 'submit') {
                // handle submit action
                // this.form.is_save = false;
                this.$emit('input', { ...this.value, is_save: false });
            }
            this.$emit('prepared', this.value);
            this.closeForm();
        },
        closeForm() {
            this.showAddressModal = false;
            // this.form = {};
        },
    },
};
</script>
<style>
.shopping-bag-btn {
    background: var(--unnamed-color-3cb207) 0% 0% no-repeat padding-box;
    background: #3cb207 0% 0% no-repeat padding-box;
    border-radius: 10px;
    opacity: 1;
    margin: 0.5rem 0;
    border: none;
    padding: 0.5rem 0;
    color: #fff;
}
</style>
